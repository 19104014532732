import GolfErpAPI from "@/api/v2/GolfErpAPI";

export const ROOT_PATH = '/stock-common';

class StockCommon {

    /** 창고 콤보 리스트 */
    async getWrhous(params) {
        const {data: data} = await GolfErpAPI.http.get(`${ROOT_PATH}/wrhous`, {params});
        return data;
    }
    /** 저장품 분류 트리  */
    async getSuppliesClassList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/suppliesClass`, {params});
        return data;
    }
    /** 저장품 리스트 조회 Popup */
    async getSuppliesCodeList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/suppliesCode`, {params});
        return data;
    }
    /** 저장품 재고리스트 조회 Popup */
    async getSuppliesStockList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/suppliesStock` ,{params} );
        return data;
    }
    /** 장비 리스트 조회 Popup */
    async getEqpCodeListPop(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/eqpCode` ,{params} );
        return data;
    }
    /** 설비 리스트 조회 Popup */
    async getFcltyCodeListPop(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/fcltyCode` ,{params} );
        return data;
    }
    /** 저장품중 유류코드 조회 */
    async getOilSupplies(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/oilSupplies` ,{params} );
        return data;
    }
    /** 작업명 트리 조회 */
    async getWorkCodeTreeList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/eqpWorkTree` ,{params} );
        return data;
    }
    /** 카트 리스트 조회 Popup */
    async getCartCodeListPop(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/cartId` ,{params} );
        return data;
    }

    /** 저장품 재고 월마감 체크
     * dateYmd : 8자리 YYYYMMDD, 6자리 YYYYMM
     * */
    async checkSuppliesClosingYm(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/checkSuppliesClosingYm` ,{params} );
        return data;
    }
    /** 저장품 재고 월마감-마지막 마감월 조회 */
    async getLastSuppliesClosingYm(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/lastSuppliesClosingYm` ,{params} );
        return data;
    }


    /** 상품 분류 트리  */
    async getProductClassList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/productClass`, {params});
        return data;
    }
    /** 상품 리스트 조회 Popup */
    async getProductCodeList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/productCode`, {params});
        return data;
    }
    /** 상품 재고 리스트 조회 Popup */
    async getProductStockList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/productStock`, {params});
        return data;
    }

    /** 상품 재고 월마감 체크
     * dateYmd : 8자리 YYYYMMDD, 6자리 YYYYMM
     * */
    async checkProductClosingYm(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/checkProductClosingYm` ,{params} );
        return data;
    }
    /** 상품 재고 월마감-마지막 마감월 조회 */
    async getLastProductClosingYm(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/lastProductClosingYm` ,{params} );
        return data;
    }
}

export default new StockCommon();
